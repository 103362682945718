import * as React from "react";
import { graphql } from 'gatsby'

import Seo from "../components/seo";
import AnimationWrapper from "../components/animationWrapper"
import BlogRoll from "../components/BlogRoll";
import Carousel from "../components/carousel";
import ContactSection from "../components/contactSection"
import Container from "../components/container"
import Header from "../components/header";
import Layout from "../components/layout";

const AktualnosciPage = ( {data, location} ) => {

  // set meta description values
  const metaDescription = data.page.frontmatter.meta_description || `Czamaninek ➤ ${data.page.frontmatter.title} ✔️ Ekologiczne materiały budowlane ✔️ Polski producent ⭐ Sprawdź!`;

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} description={metaDescription} />

      <AnimationWrapper>
        <Header data={data.page.frontmatter.header} slug={data.page.frontmatter.slug} />
      </AnimationWrapper>

      <section id="aktualnosci-blog-roll" className="page-section">
        <Container>
            <BlogRoll />
        </Container>
      </section>

      <section id="realizacje-carousel-section" className="page-section">
        <Carousel data={data.realizacjeCarousel.frontmatter.carousel_gallery} location="realizacje" />
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.contactSection.frontmatter.contact_section} />
      </section>

    </Layout>
  );
}

export const pageQuery = graphql`
  query AktualnosciPage {
    page: mdx(frontmatter: { slug: { eq: "aktualnosci" }, type: { eq: "page" } }) {
      frontmatter{
        title
        meta_description
        slug
        header{
          title
          breadcrumbs {
            title
            url
          }
          overlay
          image {
            childImageSharp {
              gatsbyImageData(
                transformOptions: {fit: COVER},
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    realizacjeCarousel: mdx(
      frontmatter: {slug: {eq: "realizacje-carousel"}, type: {eq: "global"}}
    ){
      frontmatter{
        carousel_gallery{
          text_section{
            title
            title_visible
            subtitle
            text
            button_text
            button_url
            button_style
          }
          carousel_settings{
            slides_per_view
            transition_speed
            transition_delay
            object_fit
            rotate
            stretch
            depth
            scale
            slide_shadows
          }
          images{
            title
            title_visible
            description
            status
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
      }
    }
    contactSection: mdx(
      frontmatter: {slug: {eq: "contact-section"}, type: {eq: "global"}}
    ){
      frontmatter{
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          image_object_fit
          image_object_position
        }
      }
    }
  }
`

export default AktualnosciPage
